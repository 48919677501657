import { render, staticRenderFns } from "./RoleList.vue?vue&type=template&id=885e6132&scoped=true"
import script from "./RoleList.vue?vue&type=script&lang=js"
export * from "./RoleList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "885e6132",
  null
  
)

export default component.exports