<template>
  <page-header-wrapper>
    <template slot="extra">
      <a-button @click="reback" type="primary">
        <a-icon type="rollback" />返回
      </a-button>
    </template>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <div>
          <div class="title">标题</div>
          <div class="main">
            <a-input v-model="form.title" placeholder="请输入标题" />
          </div>
        </div>
        <div>
          <div class="title">文章正文</div>
          <div class="main">
            <editor v-model='form.content' :config="config"></editor>
          </div>
        </div>
      </div>
      <div id="submit">
        <a-button @click="cancel">取消</a-button>
        <a-button type="primary" id="btn" @click="submit">提交</a-button>
      </div>
    </a-card>
  </page-header-wrapper>
</template>

<script>
  import moment from 'moment'
  import {
    Modal,
    message
  } from 'ant-design-vue'
  import editor from '@/components/editor';
  import {
    getUrlKey,
    UEDITOR_HOME_URL,
    serverUrl,
    imgUpload,
    prefix
  } from '@/utils'
  import {
    _editDoc,
    _getDocInfo
  } from '@/api/article'
  var disabled = true
  export default {
    name: '',
    components: {
      editor
    },
    data() {
      return {
        config:{
                    height:480
                },
        form: {
          id: '',
          title: '',
          type: '2',
          content: ''
        },
        myConfig: {
          autoHeightEnabled: false,
          initialFrameHeight: 360,
          initialFrameWidth: '100%',
          serverUrl: serverUrl,
          UEDITOR_HOME_URL: UEDITOR_HOME_URL
        },
      }
    },
    created() {
      this.form.id = getUrlKey('id') || '';
      if (this.form.id) {
        this.getInfo();
      }
    },
    methods: {
      getInfo() {
        let params = {
          id: this.form.id
        }
        _getDocInfo(params).then(res => {
          if (res.code == 200) {
            let data = res.data
            this.form.title = data.title
            this.form.type = data.type
            this.form.content = data.content.replace(/undefined/g, "")
          }
        })
      },
      submit() {
        let params = {
          id: this.form.id,
          content: this.form.content.replace(/undefined/g, ""),
          title: this.form.title,
          type: this.form.type
        }
        if (!this.form.title) {
          this.$message.info('请输入标题')
          return false
        } else if (!this.form.content) {
          this.$message.info('请输入内容')
          return false
        }
        if (disabled) {
          disabled = false
          _editDoc(params).then(res => {
            if (res.code == 200) {
              this.$message.success(this.form.id ? '编辑成功' : '新增成功')
              this.reset()
              setTimeout(_ => {
                this.$router.go(-1)
                disabled = true
              }, 500)
            } else {
              disabled = true;
              this.$message.error(res.msg)
            }
          })
        }

      },
      cancel() {
        this.reset();
        this.$router.go(-1);
      },
      reset() {
        this.form.id = ''
        this.form.title = ''
        this.form.content = ''
      },
      reback() {
        this.$router.go(-1)
      }
    }
  }
</script>


<style scoped>
  #submit {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  #btn {
    margin-left: 20px;
  }

  .title {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 22px;
    margin-bottom: 20px;
    padding: 0px;
  }

  .main {
    margin-bottom: 25px;
  }
</style>
